import React ,{useEffect} from 'react';
import Header from '../components/Header';
import '../i18n/i18n'
import Footer from '../components/Footer';

import Nav from '../components/Nav';
import FeaturedFull from '../components/FeaturedFull';
import ReactGA from 'react-ga4';


const ProductList = () => {

  useEffect(() => {
    document.title = "Good Club - 精彩禮品"
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname ,title : 'Good Club - 精彩禮品'});
  }, []);

  return (
    <div>
      <Header />
      <Nav />
      <FeaturedFull />
      <Footer />
    </div>
  );
};

export default ProductList;


