/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-08 20:55:24
 * @LastEditTime: 2024-05-28 21:57:01
 * @Description: 
 */

import React, { useState, useContext } from 'react';
import '../css/ProductDetailType2.css';
import { useTranslation } from 'react-i18next';
import ImageSlider from './ImageSlider';
import { Link } from 'react-router-dom';
import ProductSelectorType3 from './ProductSelectorType3';
import { AuthContext } from '../context/AuthContext';
import AddToCartDialogType3 from './AddToCartDialogType3';
import { useNavigate } from "react-router-dom";
import { API_PREFIX } from './ApiConfog';


function ProductDetailType3({ product }) {

  const { userInfo } = useContext(AuthContext);
  const token = localStorage.getItem("accessToken");
  const { t } = useTranslation();
  const [pointsNeeded, setPointsNeeded] = useState(product.price + "起");
  const [selectedProduct, setSelectedProduct] = useState(null); // Corrected state initialization
  const [count, setCount] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [conversionSuccess, setConversionSuccess] = useState(false);
  const [selectedSpec, setSelectedSpec] = useState(null);
  const haddToCartApiUrl = `${API_PREFIX}user/addToCart`;

  const [postData, setPostData] = useState({});

  let productsToPost = [];
  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleSubmitToCart = async () => {
    try {
      const response = await fetch(haddToCartApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(postData),
      });

      if (!response.ok) {
        throw new Error("Failed to convert points");
      }

      const result = await response.json();

      if (result.success) {
        // Show success message
        //console.log(result);
        setConversionSuccess(true);
      } else {
        // Handle failure
        throw new Error("Conversion failed");
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };

  const handleSubmit = () => {
    if (count > 0) {
      selectedSpec.qty = count;
      productsToPost.push(selectedSpec)
      setPostData({ products: productsToPost, user_id: userInfo.userId });
      setShowConfirmation(true);
    }
  }


  const navigate = useNavigate();
  const handleBack = () => {
    console.log("back!")
    navigate("/cart");
  };

  return (
    <div className="container">

      <div className="left">
        <div className="image-gallery">
          <ImageSlider images={product.images} />
        </div>

        <div className="image-buttons">
          {product.price_list && Object.keys(product.price_list).map(key => (
            <div key={key} className='price-div'>
              <div className='price-name'>{product.price_list[key].name}</div><div className='product-price'><img src="/images/greyIcon.png" alt="Icon" />{product.price_list[key].price}</div>
            </div>
          ))}
        </div>

        <hr />

  
      </div>

      <div className="right">
        <h2>{product.title}</h2>
        <ProductSelectorType3 subProducts={product.sub_product_l1} category={product.specCategory} spec_category_l2={product.specCategoryL2} pointsNeeded={pointsNeeded} setPointsNeeded={setPointsNeeded} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} count={count} setCount={setCount} selectedSpec={selectedSpec} setSelectedSpec={setSelectedSpec} />
        <div className="counter">
        </div>

       <div className="right-brand">
          <p>{t('product.brand')}</p>
          <a href={product.brandLink} target="_blank" rel="noreferrer">{product.brand}</a>
        </div>
        <div className="right-details">
          <p>{t('product.details')}</p>
          <p>{product.discription}</p>
        </div>
        <div className="right-details">
          <p>{t('product.details2')}</p>
          <ul className='point-form'>
            {product.detail.split('\n').map((line, index) => (
              <li key={index}> {line}</li>
            ))}
          </ul>
        </div>

        <div className="right-details">
          <p>{t('product.rem')}</p>
          <ul className='point-form'>
            {product.redeemDetail.split('\n').map((line, index) => (
              <li key={index} style={{ fontWeight: index === 0 ? 'bold' : 'normal' }}> {line}</li>
            ))}
          </ul>
        </div>

        <div className="right-details">
          <p>{t('product.terms')}</p>
          <ul className='point-form'>
            {product.terms.split('\n').map((line, index) => (
              <li key={index}> {line}</li>
            ))}
          </ul>
        </div>
      </div>

      <div className="submit-area">
        <div className="submit-title">
          <p>{t('product.submit_tittle')}</p>
        </div>
        <div className="submit-points">
          <img src="/images/greyIcon.png" alt="Icon" />
          <span className='submit-price'>{pointsNeeded}</span>
        </div>
        {userInfo && (
          <button type="submit" onClick={handleSubmit} className='gradient-button'>{t('product.submit')}</button>
        )}


        {!userInfo && (
          <Link to="/login">
            <button type="submit" className='gradient-button'>{t('product.submit')}</button>
          </Link>
        )}
      </div>

      {showConfirmation && (
        <AddToCartDialogType3
          count={count}
          postData={postData}
          onCancel={handleCancelConfirmation}
          onConfirm={handleSubmitToCart}
          conversionSuccess={conversionSuccess}
          handleBack={handleBack}
        />
      )}
    </div>
  );
};

export default ProductDetailType3;



