import React, { useState, useEffect, useContext } from 'react';
import '../css/ConvertPoints.css'; // Assuming you have a corresponding CSS file
import { useTranslation } from 'react-i18next';
import '../css/ProgressBar.css'; // Ensure you have a ProgressBar.css file
import { AuthContext } from '../context/AuthContext';
import LoginComponent from './LoginComponent';
import ConfirmationDialog from './ConfirmationDialog';
import { API_PREFIX } from './ApiConfog';
import ChangeDataDialog from './ChangeDataDialog';


const ConvertPointsComponent = () => {
  const { userInfo } = useContext(AuthContext);
  const token = localStorage.getItem("accessToken");
  const pointsApiUrl = `${API_PREFIX}user/points`;
  const convertPointsApiUrl = `${API_PREFIX}user/convertPoints`;
  const [showChangeData, setShowChangeData] = useState(false);

  useEffect(() => {
    //console.log("Use effect");
    if (userInfo) {
      const postData = { id: userInfo.userId };
      // Make a POST request to the API with the token and data
      fetch(pointsApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
        body: JSON.stringify(postData), // Include the data object here
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((responseData) => {
          // Handle the response data
          setGdPoints(responseData.userIntegral);
          setUpdatedGdPoints(responseData.userIntegral);
          setGdClubPoints(responseData.goodclubPoints);
          //console.log(responseData);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [userInfo]); // Include userInfo in the dependency array

  const [gdPoints, setGdPoints] = useState(0);
  const [gdClubPoints, setGdClubPoints] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [conversionSuccess, setConversionSuccess] = useState(false);

  const [convertedPoints, setConvertedPoints] = useState(0);
  const [updatedGdPoints, setUpdatedGdPoints] = useState(gdPoints);
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  const [points, setPoints] = useState(0);

  const handleOnClickRemAll = () => {
    setPoints(Math.floor(gdPoints / 200) * 200);
    handlePointsChange(points); // Call the callback function with the new value
  }

  const handleSliderChange = (event) => {
    const newPoints = Number(event.target.value);
    setPoints(newPoints);
    handlePointsChange(newPoints); // Call the callback function with the new value
  };

  const handlePointsChange = (points) => {
    setConvertedPoints(points);
    setUpdatedGdPoints(gdPoints - points);
  }

  useEffect(() => {
    if (points !== undefined) { // Ensure that points is defined, adjust as needed based on initial value conditions
      handlePointsChange(points); // Call the callback function with the new value
    }
  }, [points]); // Effect runs only when `points` changes

  const handleSubmit = () => {
    if (convertedPoints > 0) {
      setShowConfirmation(true);
    }
  }

  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleConfirmConversion = async () => {
    try {
      const response = await fetch(convertPointsApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ "convertPoints": convertedPoints, "id": userInfo.userId }),
      });

      if (!response.ok) {
        throw new Error("Failed to convert points");
      }

      const result = await response.json();

      if (result.success) {
        // Show success message
        console.log(result);
        setConversionSuccess(true);
      } else {
        // Handle failure
        throw new Error("Conversion failed");
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };


  return (

    <div>
      {userInfo && (

        <div className="convert-points-container" id="convert-points">

          <h2 className="convert-points-title">{t('convert.title')}</h2>

          <div className="cp-container">
            <div className="left">
              <p className="username">{userInfo ? userInfo.userName : 'Loading...'}</p>
              <button className="update-button" onClick={() => setShowChangeData(true)} >{t('convert.update')}</button>
            </div>
            <div className="cp-right">
              <h2>{t('convert.title2')}</h2>
              <div className="cp-info">
                <img src="/images/greyIcon.png" alt="Icon" />
                <span className="number">{gdClubPoints + (convertedPoints / 200)}</span>
              </div>
            </div>
          </div>
          <br />
          <hr className="divider" />
          <br />
          <div className="gd-container">

            <div className="gd-left">

              <p className="gd-points">{t('convert.gdgegm')}{t('convert.balance')}</p>
              <p className="gd-points">** 每 200 Good Mask 積分可兌換 1 點 Good Club 積分 </p>


              <div className='hover-target' onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}>

                {!isHovered && (
                  <div>
                    <img src="/images/box3_icon.png" alt="Icon" />
                    <img src="/images/box4_icon.png" alt="Icon" />
                    <img src="/images/box5_icon.png" alt="Icon" />
                  </div>
                )}

                {isHovered && (
                  <div className="circle">
                    <span>{updatedGdPoints}</span>
                  </div>
                )}
              </div>
            </div>
            <div className="gd-right">
              <div className="cp-info">
                <span className="gd-number">GoodMask 積分結餘 : {updatedGdPoints}</span>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div>
            <div className="progress-bar-container">
              <input
                type="range"
                min="0"
                step="200"
                max={gdPoints}
                value={points}
                onChange={handleSliderChange}
                className="progress-bar"
              />
              <div className="progress-bar-filled" style={{ width: `${(points / gdPoints) * 100}%` }}></div>
              <div className="points-display" style={{ left: `${(points / gdPoints) * 100}%` }}>

                <span className="points-text">{Math.floor(points / 200)}</span>
              </div>
            </div>

            <br /><br />
            <div className="convert-buttons">
              <button className="gift-list-button" onClick={handleOnClickRemAll}>{t('convert.remAll')}</button>
              <button onClick={handleSubmit} className="submit-button">{t('convert.submit')}</button>
            </div>
          </div>
          <br />
        </div>
      )}

      {!userInfo && (
        <LoginComponent />
      )}

      {showConfirmation && (
        <ConfirmationDialog
          pointsToConvert={convertedPoints}
          onCancel={handleCancelConfirmation}
          onConfirm={handleConfirmConversion}
          conversionSuccess={conversionSuccess}
        />
      )}


      {showChangeData && (
        <ChangeDataDialog
        setShowChangeData = {setShowChangeData}
        userId = {userInfo.userId}
        />
      )}

    </div>

  );
};

export default ConvertPointsComponent;

//<ProgressBar gdPoints={gdPoints} onPointsChange={handlePointsChange} />