import React ,{useEffect} from 'react';
import Header from '../components/Header';
import '../i18n/i18n'
import Footer from '../components/Footer';
import LoginComponent from '../components/LoginComponent';
import Nav from '../components/Nav';
import ReactGA from 'react-ga4';


const Login = () => {

  useEffect(() => {
    document.title = "Good Club - 會員登入"
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname ,title : 'Good Club - 會員登入'});
  }, []);
  
  return (
    <div>
      <Header />
      <Nav />
      <LoginComponent />
      <Footer />
    </div>
  );
};

export default Login;


