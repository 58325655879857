/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-08 20:55:24
 * @LastEditTime: 2024-05-28 21:56:36
 * @Description: 
 */

import React, { useState, useContext, useEffect, useRef } from 'react';
import '../css/ProductDetailType2.css';
import { useTranslation } from 'react-i18next';
import ImageSlider from './ImageSlider';
import ProductSelectorType2 from './ProductSelectorType2';
import { AuthContext } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import AddToCartDialog from './AddToCartDialogType2';
import { useNavigate } from "react-router-dom";
import { API_PREFIX } from './ApiConfog';


function ProductDetailType2({ product }) {

  const { userInfo } = useContext(AuthContext);
  const token = localStorage.getItem("accessToken");
  const { t } = useTranslation();
  const [pointsNeeded, setPointsNeeded] = useState(product.price + "起");
  const [selectedProduct, setSelectedProduct] = useState(null); // Corrected state initialization
  const [quantities, setQuantities] = useState({});
  const [postData, setPostData] = useState({});

  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [conversionSuccess, setConversionSuccess] = useState(false);
  const haddToCartApiUrl = `${API_PREFIX}user/addToCart`;


  const handleSubmitToCart = async () => {
    try {
      const response = await fetch(haddToCartApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(postData),
      });

      if (!response.ok) {
        throw new Error("Failed to convert points");
      }

      const result = await response.json();

      if (result.success) {
        // Show success message
        //console.log(result);
        setConversionSuccess(true);
      } else {
        // Handle failure
        throw new Error("Conversion failed");
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  };

  /*
  
    // Use a ref to track the latest value of the postData state
    const postDataRef = useRef(postData);
  
    useEffect(() => {
      // Update the ref whenever postData changes
      postDataRef.current = postData;
    }, [submitButtonClicked, quantities, selectedProduct, userInfo]);
  
    const handleSubmit = () => {
      setSubmitButtonClicked(true);
      console.log(postDataRef);
    };
  
    useEffect(() => {
      if (submitButtonClicked) {
        // Get the values of the first array
        const values = Object.values(quantities);
        // Calculate the sum using reduce
        const sum = values.reduce((acc, curr) => acc + curr, 0);
        if (sum > 0) {
          setShowConfirmation(true);
          const selectSpec = selectedProduct.spec.map(spec => {
            // Get the quantity from array1 based on the spec id
            const qty = quantities[spec.id] || 0;
            // Create a new object by spreading the existing spec object and adding the qty property
            return { ...spec, qty };
          });
          setPostData({ ...postDataRef.current, products: selectSpec, user_id: userInfo.userId });
  
        }
        // Reset the submit button clicked state
        setSubmitButtonClicked(false);
        //console.log(postData);
      }
    }, [submitButtonClicked, quantities, selectedProduct, userInfo]);
  
  
    */

  const handleSubmit = () => {
    //console.log("p" + pointsNeeded);
    //console.log(quantities)
    //console.log(selectedProduct.spec)
    // Get the values of the first array
    const values = Object.values(quantities);
    // Calculate the sum using reduce
    const sum = values.reduce((acc, curr) => acc + curr, 0);
    if (sum > 0) {
      const selectSpec = selectedProduct.spec.map(spec => {
        // Get the quantity from array1 based on the spec id
        const qty = quantities[spec.id] || 0;
        setShowConfirmation(true)
        // Create a new object by spreading the existing spec object and adding the qty property
        return { ...spec, qty };
      });

      setPostData({ products: selectSpec, user_id: userInfo.userId });
    }
  }


  const navigate = useNavigate();
  const handleBack = () => {
    console.log("back!")
    navigate("/cart");
  };

  const handleCancelConfirmation = () => {
    setShowConfirmation(false);
  };

  let productsToPost = [];


  return (
    <div className="container">

      <div className="left">
        <div className="image-gallery">
          <ImageSlider images={product.images} />
        </div>

        <div className="image-buttons">
          {product.price_list && Object.keys(product.price_list).map(key => (
            <div key={key} className='price-div'>
              <div className='price-name'>{product.price_list[key].name}</div><div className='product-price'><img src="/images/greyIcon.png" alt="Icon" />{product.price_list[key].price}</div>
            </div>
          ))}
        </div>

        <hr />


      </div>

      <div className="right">
        <h2>{product.title}</h2>
        <ProductSelectorType2 subProducts={product.sub_product_l1} category={product.specCategory} pointsNeeded={pointsNeeded} setPointsNeeded={setPointsNeeded} selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} quantities={quantities} setQuantities={setQuantities} />
        <div className="counter">
        </div>
       
        <div className="right-brand">
          <p>{t('product.brand')}</p>
          <a href={product.brandLink} target="_blank" rel="noreferrer">{product.brand}</a>
        </div>
        <div className="right-details">
          <p>{t('product.details')}</p>
          <p>{product.discription}</p>
        </div>
        <div className="right-details">
          <p>{t('product.details2')}</p>
          <ul className='point-form'>
            {product.detail.split('\n').map((line, index) => (
              <li key={index}> {line}</li>
            ))}
          </ul>
        </div>

        <div className="right-details">
          <p>{t('product.rem')}</p>
          <ul className='point-form'>
            {product.redeemDetail.split('\n').map((line, index) => (
              <li key={index} style={{ fontWeight: index === 0 ? 'bold' : 'normal' }}> {line}</li>
            ))}
          </ul>
        </div>

        <div className="right-details">
          <p>{t('product.terms')}</p>
          <ul className='point-form'>
            {product.terms.split('\n').map((line, index) => (
              <li key={index}> {line}</li>
            ))}
          </ul>
        </div>
      </div>

      <div className="submit-area">
        <div className="submit-title">
          <p>{t('product.submit_tittle')}</p>
        </div>
        <div className="submit-points">
          <img src="/images/greyIcon.png" alt="Icon" />
          <span className='submit-price'>{pointsNeeded}</span>
        </div>
        {userInfo && (
          <button type="submit" onClick={handleSubmit} className='gradient-button'>{t('product.submit')}</button>
        )}


        {!userInfo && (
          <Link to="/login">
            <button type="submit" className='gradient-button'>{t('product.submit')}</button>
          </Link>
        )}

        {showConfirmation && (

          <AddToCartDialog
            postData={postData}
            onCancel={handleCancelConfirmation}
            onConfirm={handleSubmitToCart}
            conversionSuccess={conversionSuccess}
            handleBack={handleBack}
          />
        )}
      </div>
    </div>
  );
};

export default ProductDetailType2;



