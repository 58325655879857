import React, { useState } from 'react';
import '../css/CheckBoxGroup.css'; // Make sure to include the CSS file

function CheckboxGroup({checkedItems, setCheckedItems,values }) {


  const handleChange = (index) => {
    setCheckedItems(prevItems => {
      const newItems = [...prevItems];
      newItems[index] = !newItems[index];
      return newItems;
    });
  };

  return (
    <div className="checkbox-group">
      <div className="row">
        {values.slice(0, 5).map((value, index) => (
          <label key={index} className="checkbox-container">
            <input
              type="checkbox"
              checked={checkedItems[index]}
              onChange={() => handleChange(index)}
            />
            <span className={checkedItems[index] ? "checkmark checked" : "checkmark"}></span>
            {value}
          </label>
        ))}
      </div>
      <div className="row">
        {values.slice(5).map((value, index) => (
          <label key={index + 5} className="checkbox-container">
            <input
              type="checkbox"
              checked={checkedItems[index + 5]}
              onChange={() => handleChange(index + 5)}
            />
            <span className={checkedItems[index + 5] ? "checkmark checked" : "checkmark"}></span>
            {value}
          </label>
        ))}
      </div>
    </div>
  );
}

export default CheckboxGroup;
