import React from 'react';
import '../css/ConfirmationDialog.css'; // Assuming you have corresponding CSS file

const handleBack = () => {
  window.location.reload(); // This will refresh the page
};

const ConfirmationDialog = ({ pointsToConvert, onCancel, onConfirm ,conversionSuccess}) => {
  return (
    
    <div className="confirmation-overlay">
      {!conversionSuccess && (
      <div className="confirmation-dialog">
        <h2>確認兌換</h2>
        <p>你將會使用 {pointsToConvert} GDMASK 積分兌換成 GOODCLUB {pointsToConvert / 200} 積分</p>
        <div className="button-container">
          <button onClick={onCancel}>返回</button>
          <button onClick={onConfirm}>確認</button>
        </div>
      </div>)}

      {conversionSuccess && (
      <div className="confirmation-dialog">
        <p>兌換成功</p>
        <div className="button-container">
         <button onClick={handleBack}>返回</button>
         </div>
      </div>)}
    </div>
  );
};

export default ConfirmationDialog;
