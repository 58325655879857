import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import '../css/About.css';
import useScrollToAnchor from '../until/useScrollToAnchor';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const About2 = () => {
  const { userInfo } = useContext(AuthContext);
  const { t } = useTranslation();
  useScrollToAnchor();

  return (
    <div className="about-section" id="about-div">
      <h2>想緊貼我們的獨家優惠?</h2>
      <p>立即 Follow 我們 GOOD CLUB by Good Mask 的 Facebook 及IG 啦！</p>
      <div className="aboutus-image-container">
        <a href="https://www.facebook.com/goodclubhk" target='_blank' rel="noreferrer"><img src="/images/about_fb.png" className='aboutus-image' alt='aboutfb' /></a>
        <a href="https://www.instagram.com/goodclubhk/" target='_blank' rel="noreferrer"><img src="/images/about_ig.png" className='aboutus-image' alt='aboutig' /></a>
      </div>

      <img src="/images/line.png" className='line-image' alt='aboutfb' />

      {!userInfo && (
        <div>
          <div>
          <Link to="/login">
            <h2>想知自己有多少積分? 立即登入</h2>
            <p>(同時可查核Good Mask 積分及兌換成 GOOD CLUB 積分)</p>
            <h2>未成為會員！？立即登記</h2>
            <p>(Good Mask 會員全面升級成為 Good Club 會員，無需重新登記)</p>
            <br></br>
            
              <button className="gradient-button">{t('about.reg')}</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default About2;
