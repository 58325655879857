/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-25 17:03:06
 * @LastEditTime: 2024-05-26 17:49:49
 * @Description: 
 */
import React, { useState } from 'react';
import '../css/ConfirmationDialog.css'; // Assuming you have corresponding CSS file
import { API_PREFIX } from './ApiConfog';


const ForgetPassword = ({ forgetPassword, setForgetPassword, resetSuccess, setResetSuccess }) => {

  const [phoneNo, setPhoneNo] = useState('');
  const [code, setCode] = useState('');
  const [phoneNoValid, setPhoneValid] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [phoneNoOK, setPhoneNoOK] = useState(false);
  const [codeOK, setCodeOk] = useState(false);
  const checkPhoneExistApiUrl = `${API_PREFIX}auth/checkPhoneExist/`;
  const checkResetSMSApiUrl = `${API_PREFIX}auth/checkResetSMS`;


  const handlePhoneNoChange = (e) => {
    if (e.target.value.length === 8) {
      setPhoneNoOK(true);
      setPhoneNo(e.target.value);
    } else {
      setPhoneNoOK(false);
    }
  };

  const handleCodeChange = (e) => {
    if (e.target.value.length === 6) {
      setCodeOk(true);
      setCode(e.target.value);
    } else {
      setPhoneNoOK(false);
    }
  };


  const handleGetSMS = () => {
    console.log("clicked");
    const fetchData = async () => {
      //console.log(userInfo.userId)
      try {
        const response = await fetch(checkPhoneExistApiUrl + phoneNo); // Replace with your API endpoint
        if (!response.ok) {
          throw new Error('Fail to fetch data');
        }
        const jsonData = await response.json();
        if (!jsonData.success) {
          setErrMsg(jsonData.errorMessage)
          console.log(jsonData);
        }
        else {
          setPhoneValid(true)
        }
      } catch (error) {
        setErrMsg(error);
      }
    };
    fetchData();
  };

  const handleCheckSMS = () => {

    const handlecConfirmSMS = async (code, phoneNo) => {
      try {
        const response = await fetch(checkResetSMSApiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ phoneNo, code }),
        });

        if (!response.ok) {
          throw new Error('Failed to submit code');
        }
        const data = await response.json();
        if (data.success) {
          setResetSuccess(true);
          console.log("SMS OK");
        }

      } catch (error) {
        console.error('Error submitting code:', error);
      }
    };
    handlecConfirmSMS(code, phoneNo);
  };


  return (

    <div className="confirmation-overlay">
      {!phoneNoValid && !resetSuccess && (
        <div className="confirmation-dialog">
          <h2 style={{ textAlign: 'center' }}>重置密碼</h2>
          <p>{errMsg}</p>
          <input style={{
            width: '90%',
            padding: '10px',
            border: '2px solid #666666'
          }}
            type="tel"
            id="phone"
            placeholder="請輸入電話號碼"
            maxLength={8}
            onChange={handlePhoneNoChange}
            required
          />
          <br />
          <button disabled={(!phoneNoOK)} onClick={handleGetSMS}>下一步</button>
          <button onClick={() => (setForgetPassword(false))}>返回</button>
          <div className="button-container">
          </div>
        </div>)}



      {phoneNoValid && !resetSuccess && (
        <div className="confirmation-dialog">
          <h2 style={{ textAlign: 'center' }}>請輸入驗證碼</h2>
          <p>{errMsg}</p>
          <input style={{
            width: '90%',
            padding: '10px',
            border: '2px solid #666666'
          }}
            type="text"
            id="text"
            placeholder="請輸入驗證碼"
            maxLength={6}
            onChange={handleCodeChange}
            required
          />
          <button disabled={(!codeOK)} onClick={handleCheckSMS}>確認</button>
          <button onClick={() => (setForgetPassword(false))}>返回</button>
          <div className="button-container">

          </div>
        </div>)}

        {resetSuccess && (
        <div className="confirmation-dialog">
          <h2 style={{ textAlign: 'center' }}>重置成功</h2>
          <p>你的密碼已經SMS短訊發送到你的電話號碼</p>
          <button onClick={() => (setForgetPassword(false))}>返回</button>
          <div className="button-container">

          </div>
        </div>)}

    </div>
  );
};

export default ForgetPassword;
