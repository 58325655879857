/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-01-14 12:05:35
 * @LastEditTime: 2024-01-21 12:03:53
 * @Description: 
 */
import React from 'react';
import Header from '../components/Header';
import Nav from '../components/Nav';
import '../i18n/i18n'
import Footer from '../components/Footer';
import NonDisComponent from '../components/Non_disComponent';


const Non_dis = () => {
  return (
    <div>
      <Header />
      <Nav />
      <NonDisComponent />
      <Footer />
    </div>
  );
};

export default Non_dis;


