/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-08 20:55:24
 * @LastEditTime: 2024-05-28 21:30:16
 * @Description: 
 */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProductDetailType0 from './ProductDetailType0';
import ProductDetailType1 from './ProductDetailType1';
import ProductDetailType2 from './ProductDetailType2';
import ProductDetailType3 from './ProductDetailType3';
import ProductDetailType4 from './ProductDetailType4';
import { API_PREFIX } from './ApiConfog';

const ProductDetails = () => {


  const { id } = useParams();
  const [product, setProduct] = useState([]);
  const [error,setError] = useState(null);
  const hproductApiUrl = `${API_PREFIX}auth/product/`;



  useEffect(() => {
    const fetchData = async () => {
      try{
        const response = await   fetch(hproductApiUrl + id );
        //const response = await   fetch('/apiDemo/'+ id );
        ////const response = await   fetch('https://gdmifoodmage.gdmask.com/apiDemo/'+ id );
        if(!response.ok){
          throw new Error('Fail to fetch data');
        }
        const jsonData = await response.json();
        setProduct(jsonData);
      } catch (error){
        setError(error);
      }
    };
    fetchData();

  },[id]);

if(error){
  return (  
    <div>
    <h2>{error.message}</h2>
    </div>)
}

if (!product.sub_product_l1 || product.sub_product_l1.length === 0) {
  return <div>No sub products available</div>; // or render an appropriate message
}

if(product.type === 0){
  return  <ProductDetailType0 product={product} />
}

if(product.type === 1){
  return  <ProductDetailType1 product={product} />
}

if(product.type === 3){
  return  <ProductDetailType3 product={product} />
}

if(product.type === 4){
  return  <ProductDetailType4 product={product} />
}

  return (
    <ProductDetailType2 product={product} />
  );
};

export default ProductDetails;



