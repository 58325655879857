import React from 'react';
import { useTranslation } from 'react-i18next';
import '../css/About.css';
import useScrollToAnchor from '../until/useScrollToAnchor';

const About = () => {
  const { t } = useTranslation();
  useScrollToAnchor();

  return (
    <div className="about-section" id="about-div">
      <br></br>
      <h2>{t('about.about_title')}</h2>
      <p>{t('about.about_content1')}<br />{t('about.about_content2')}</p>
      <p>{t('about.about_content3')}<br />{t('about.about_content4')}</p>
      <br />
    </div>
  );
};

export default About;
