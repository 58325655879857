/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-23 21:40:18
 * @LastEditTime: 2024-05-28 15:09:02
 * @Description: 
 */
import React, { useState, useContext } from 'react';
import '../css/LoginComponent.css'; // Ensure you have a LoginComponent.css file
import { Link, useNavigate } from 'react-router-dom';
import rightSideImage from '../assets/login_backgroung.png'; // Update with the path to your image
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import ForgetPassword from './ForgetPassword'
import { API_PREFIX } from './ApiConfog';


const LoginComponent = () => {
  const [userAccount, setUserAccount] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const { t } = useTranslation();
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Import useNavigate
  const { userInfo, login } = useContext(AuthContext);
  const [forgetPassword, setForgetPassword] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const loginApiUrl = `${API_PREFIX}auth/login`;


  if (userInfo) {
    console.log(setIsPasswordShown);
    navigate("/");
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(loginApiUrl, {
        userAccount,
        userPassword
      });
      const { accessToken, success, message } = response.data;
      if (success) {
        // Store access token in local storage or session storage
        login(accessToken);
        setError();
        navigate('/');
        // Optionally perform any other actions upon successful login
      } else {
        // Display error message if login is unsuccessful
        setError(message);
      }
    } catch (error) {
      // Handle login error, e.g., display an error message to the user
      setError('Login failed. Please try again later.');
      console.error('Login failed:', error);
    }
  };

  return (
    <div className="login-container">

      <div className="login-left">
        <form>
          <h2 className="login-title">{t('login.title')}</h2>
          {error && <p className="error-message">{error}</p>} {/* Conditionally render error message */}
          <p className="login-description">Good Mask 會員全面自動升級為 Good Club 會員,<br /> 無需重新登記</p>
          <div className="login-form-group">
            <label htmlFor="username">{t('login.login')}</label>
            <input type="text" id="username" placeholder={t('login.login_placeholder')} value={userAccount} onChange={(e) => setUserAccount(e.target.value)} required />
          </div>
          <div className="login-form-group">
            <label htmlFor="password">{t('login.password')}</label>
            <input type={isPasswordShown ? 'text' : 'password'}
              value={userPassword}
              onChange={(e) => setUserPassword(e.target.value)}
              placeholder={t('login.password_placeholder')}
              required
              autoComplete="on"
            />
          </div>
          <div className="login-bottom">
            <div className="remember-me">
              <input type="checkbox" id="rememberMe" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
              <label htmlFor="rememberMe">{t('login.remember')}</label>
            </div>
            <p className='login-description' onClick={() => (setForgetPassword(true))} style={{ cursor: 'pointer' }}>{t('login.forgot')}</p>
          </div>
          <button className="login-button" onClick={handleSubmit}>{t('login.submit')}</button>
        </form>
      </div>


      <div className="login-right" style={{ backgroundImage: `url(${rightSideImage})` }}>
        <h2 className="signup-title">{t('login.reg_now')}</h2>
        <Link to="/register"><button className="signup-button">{t('login.sign_up')}</button></Link>
      </div>

      {forgetPassword && (
        <ForgetPassword
          forgetPassword={forgetPassword}
          setForgetPassword={setForgetPassword}
          resetSuccess={resetSuccess}
          setResetSuccess={setResetSuccess}
        />
      )}

    </div>
  );
};

export default LoginComponent;
