import React, { useRef, useEffect, useState } from 'react';
import '../css/SMSCodeInput.css'; // Make sure to include the CSS file
import { API_PREFIX } from './ApiConfog';

function SMSCodeInput({smsVerity, setSMSVerity, phoneNo}) {


  const inputRefs = useRef(Array.from({ length: 6 }, () => React.createRef()));
  const [code, setCode] = useState('');
  const rcheckSMSApiUrl = `${API_PREFIX}auth/checkSMS`;

  useEffect(() => {
    const focusNextInput = (index) => {
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].current.focus();
      }
    };


    const handleChange = (index, e) => {
      const value = e.target.value;
      if (value && value.length === 1 && !isNaN(value)) {
        // Clear the code state before updating it with the new value
        setCode(prevCode => prevCode.slice(0, index) + value + prevCode.slice(index + 1));
        focusNextInput(index);
      }
    };

    inputRefs.current.forEach((ref, index) => {
      ref.current.addEventListener('input', (e) => handleChange(index, e));

      // Cleanup
      return () => {
        ref.current.removeEventListener('input', (e) => handleChange(index, e));
      };
    });
  }, []);

  useEffect(() => {

    if (code.length === 6) {
      const handleCodeEntered = async (code, phoneNo) => {
        try {
          const response = await fetch(rcheckSMSApiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ phoneNo, code, "text": "c1" }),
          });

          if (!response.ok) {
            throw new Error('Failed to submit code');
          }
          const data = await response.json();
          if(data.success){
            setSMSVerity(true);
          }
          
        } catch (error) {
          console.error('Error submitting code:', error);
        }
      };
      handleCodeEntered(code, phoneNo);
      // Make the API call when all digits are entered
      // onCodeEntered(code);
    }
  }, [code, phoneNo]);

  return (
    <div className="sms-code-input">
      {inputRefs.current.map((ref, index) => (
        <input
          key={index}
          ref={ref}
          type="text"
          maxLength={1}
          className='smscode'
          disabled={smsVerity}
        />
      ))}
    </div>
  );
}

export default SMSCodeInput;
