/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-25 16:57:22
 * @LastEditTime: 2024-05-30 22:18:29
 * @Description: 
 */
import React, { useEffect, useState } from 'react';
import '../css/ProductSelector.css';
//import { useTranslation } from 'react-i18next';

function ProductSelector({ subProducts, category, spec_category_l2, pointsNeeded, setPointsNeeded, selectedProduct, setSelectedProduct, count, setCount ,selectedSpec, setSelectedSpec}) {




  useEffect(() => {
    //console.log("useEffect triggered");
    let newTotalPrice = 0;

    // Add selected product price
    if (selectedProduct) {
      newTotalPrice += selectedProduct.price || 0;
    }

    // Add spec prices based on quantities
    if (selectedProduct && selectedSpec) {
      newTotalPrice += (selectedSpec.price || 0) * count;
    }

    //console.log("New Total Price:", newTotalPrice);

    setPointsNeeded(newTotalPrice);
  }, [count, selectedProduct]);


  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setCount(0);
  };

  const handleSpecSelect = (spec) => {
    setSelectedSpec(spec);
    setCount(0);
  };


  const handleQuantityChange = (change) => {
    setCount(prevQuantity => Math.max(prevQuantity + change, 0));
  };

  return (
    <div>
      <span className='category_title'>{category}</span><br />
      <div className='product_select'>
        {subProducts && subProducts.map((product, index) => (
          <button
            key={index}
            className={selectedProduct === product ? 'product-selected' : 'product-unselected'}
            onClick={() => handleProductSelect(product)}>
            {product.product_name}
          </button>
        ))}
      </div>

      {selectedProduct && selectedProduct.spec && (
        <div className="spec-container">
          <span className='category_title'>{spec_category_l2}</span>
          <div className='specs-wrapper'>
            {selectedProduct.spec.map((spec, index) => (
              <button
                key={index}
                className={selectedSpec === spec ? 'product-selected' : 'product-unselected'}
                onClick={() => handleSpecSelect(spec)}>
                {spec.specName}
              </button>
            ))}
          </div>
        </div>
      )}

      {selectedSpec && (
        <div className="qty-container">
          <div className='qty-wrapper'>
            <button
              className="quantity-btn"
              onClick={() => handleQuantityChange(-1)}>
              －
            </button>
            <span className='qty_value'>{count}</span>
            <button
              className="quantity-btn"
              onClick={() => handleQuantityChange(1)}>
              ＋
            </button>
          </div>
        </div>
      )}

    </div>
  );
}

export default ProductSelector;
