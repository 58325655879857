import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import '../css/RegisterComponent.css'; // Make sure to include the CSS file
import SMSCodeInput from './SMSCodeInput';
import CheckboxGroup from './CheckBoxGroup';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from "react-router-dom";
import SMSErrorDialog from './SMSErrorDialog';
import ResisterSubmitDialog from './ResisterSubmitDialog';
import { API_PREFIX } from './ApiConfog';

const RegisterComponent = () => {
  const [formData, setFormData] = useState({
    username: '',
    phoneNumber: '',
    title: '',
    email: '',
    password: '',
    confirmPassword: '',
    age: ''
  });

  const isFormDataComplete = Object.values(formData).every(value => value.trim() !== '');

  const isEmailValid = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isPasswordValid = (password) => {
    if (password.length < 8) {
      return false; // Password is too short
    }
    return true; // Password is valid
  };

  const isPasswordMatch = (password, confirmPassword) => {
    if (password.length < 8) {
      return false; // Password is too short
    }
    if (password !== confirmPassword) {
      return false; // Passwords don't match
    }
    return true; // Password is valid
  };

  const navigate = useNavigate();


  const { userInfo, login } = useContext(AuthContext);
  const { t } = useTranslation();
  const [selectedTitle, setSelectedTitle] = useState('');
  const [selectedAge, setSelectedAge] = useState('');
  const [smsVerity, setSMSVerity] = useState(false);
  const [phoneNoOK, setPhoneNoOK] = useState(false);
  const [smsErrorMsg, setSmsErrorMsg] = useState(null)
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [smsSent, setSmsSent] = useState(false)
  const [checkedItems, setCheckedItems] = useState(Array(10).fill(false));
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(true);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [regSuccess, SetRegSuccess] = useState(false);
  const [regMessage, setRegMessage] = useState("註冊中");
  const ggetSMSApiUrl = `${API_PREFIX}auth/GetSMS/`;
  const registerApiUrl = `${API_PREFIX}auth/register`;

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleCheckboxChange2 = (event) => {
    setIsChecked2(event.target.checked);
  };

  if (userInfo) {
    navigate("/");
  }

  const handleBack = () => {
    navigate("/");
  };

  const handleCancel = () => {
    setSubmitClicked(false);
  };

  const handleTitleSelect = (title) => {
    setSelectedTitle(title);
    setFormData({ ...formData, title: title });
  };

  const handleAgeSelect = (selectedAge) => {
    setSelectedAge(selectedAge);
    setFormData({ ...formData, age: selectedAge });
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleInputChangeWithPhoneChange = (e) => {
    handleInputChange(e);
    handlePhoneNoChange(e);
  };

  const handlePhoneNoChange = (e) => {
    if (e.target.value.length === 8) {
      setPhoneNoOK(true);
    } else {
      setPhoneNoOK(false);
    }
  };

  const handleRequestSMS = (e) => {
    const fetchData = async () => {
      //console.log(userInfo.userId)
      try {
        const response = await fetch(ggetSMSApiUrl + formData.phoneNumber); // Replace with your API endpoint
        if (!response.ok) {
          throw new Error('Fail to fetch data');
        }
        const jsonData = await response.json();
        if (!jsonData.success) {
          setSmsErrorMsg(jsonData.errorMessage)
          setShowErrorDialog(true);
          console.log("SMSERROR:" + smsErrorMsg);
        }
        setSmsSent(true)
      } catch (error) {
        setSmsErrorMsg(error);
      }
    };
    fetchData();
  };


  const handleSubmit = () => {
    setSubmitClicked(true);
    const postData = async () => {
      try {
        const response = await fetch(registerApiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            formData: formData,
            checkedItems: checkedItems,
            isChecked: isChecked,
            isChecked2: isChecked2
          })
        });
        if (response.ok) {
          const responseData = await response.json();
          const { accessToken, success, message } = responseData;
          if (success) {
            // Store access token in local storage or session storage
            login(accessToken);
            SetRegSuccess(true);
            setRegMessage("註冊成功");
          } else {
            // Display error message if registration is unsuccessful
            console.log(message);
          }
        } else {
          // Handle HTTP error responses (status not 2xx)
          console.error('HTTP error:', response.status);
        }
      } catch (error) {
        console.error('Error submitting data:', error);
      }
    };
    postData();

  };


  // Add submit handler and other necessary logic here

  return (
    <div className="register-container">
      <h2 className="register-title">{t('register.reg_title')}</h2>
      <p className="register-description">{t('register.discription')}</p>

      <form>
        <div className="form-content">
          <div className="form-left">
            {/* Username Field */}
            <label htmlFor="username">{t('register.name')}<span>*</span></label>
            <input
              className='input'
              type="text"
              id="username"
              name="username"
              placeholder={t('register.name')}
              value={formData.username}
              onChange={handleInputChange}
              required
            />
            <label htmlFor="phone">{t('register.phone')}<span>*</span></label>
            {/* Code and Phone Number Fields */}
            <div className="code-phone-group">
              <input
                type="text"
                className="code-field"
                name="code"
                disabled
                value={formData.code}
                onChange={handleInputChange}
                placeholder="+852"
              />
              <input
                type="tel"
                maxLength='8'
                placeholder={t('register.phone')}
                className="phone-field"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChangeWithPhoneChange}
                onKeyDown={(e) => {
                  const key = e.key;
                  if (!(key === "Backspace" || key === "Delete" || /[0-9]/.test(key))) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <label>{t('register.age')}</label>
            <div className="age-selection">
              {['18-30', '31-40', '41-50', '51-60', t('register.60up')].map((age) => (
                <div key={age} className={`age-option ${selectedAge === age ? 'selected' : ''}`} onClick={() =>
                  handleAgeSelect(age)}
                >
                  {age}
                </div>
              ))}
            </div>

            {/* Password Field */}
            <label htmlFor="password">{t('register.password')}<span>*</span>
              {formData.password && !isPasswordValid(formData.password) && (<span style={{ color: 'red', fontWeight: 'bold' }}>密碼長度不足(最少8位)</span>)}
            </label>
            <input
              className='input'
              type="password"
              id="password"
              name="password"
              placeholder={t('register.password')}
              value={formData.password}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-right">
            {/* Gender Selection */}
            <label>{t('register.title')}</label>

            <div className="title-selection">
              {[t('register.mr'), t('register.miss'), t('register.mrs')].map((title) => (
                <div key={title} className={`title-option ${selectedTitle === title ? 'selected' : ''}`} onClick={() =>
                  handleTitleSelect(title)}
                >
                  {title}
                </div>
              ))}
            </div>

            {/* Email Field */}
            <label htmlFor="sms-code">{t('register.please_enter_code')}
              <span>*</span>
              {phoneNoOK && !smsSent && (<span style={{ cursor: 'pointer', color: 'blue', fontWeight: 'bold' }} onClick={handleRequestSMS}>{t('register.send_code')}</span>)}
              {smsSent && (<span>已成功發送</span>)}
            </label>
            {!phoneNoOK && (<input
              className='input'
              type="email"
              disabled

              placeholder='請先輸入正確電話號碼'

            />)}
            {phoneNoOK && (
              <SMSCodeInput
                smsVerity={smsVerity}
                setSMSVerity={setSMSVerity}
                phoneNo={formData.phoneNumber} />)}


            <label htmlFor="email">{t('register.email')}<span>*</span>
              {formData.email && !isEmailValid(formData.email) && (<span style={{ color: 'red', fontWeight: 'bold' }}>電郵地址不正確</span>)}

            </label>
            <input
              className='input'
              type="email"
              id="email"
              name="email"
              required
              value={formData.email}
              placeholder={t('register.email')}
              onChange={handleInputChange}
            />

            {/* Confirm Password Field */}
            <label htmlFor="confirmPassword">{t('register.password_repeat')}<span>*</span>
              {formData.password && !isPasswordMatch(formData.password, formData.confirmPassword) && (<span style={{ color: 'red', fontWeight: 'bold' }}>密碼不匹配</span>)}
            </label>
            <input
              placeholder={t('register.password_repeat')}
              className='input'
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleInputChange}
              required
            />
          </div>
        </div>


        <CheckboxGroup checkedItems={checkedItems} setCheckedItems={setCheckedItems} values={[t('register.option1'), t('register.option2'), t('register.option3'), t('register.option4'), t('register.option5'), t('register.option6'), t('register.option7'), t('register.option8'), t('register.option9'), t('register.option10')]} />
        <br />
        <div className="checkbox-with-text">
          <div className="termsCheckboxRow">
            <input type="checkbox" className='termsCheckbox' required
              checked={isChecked}
              onChange={handleCheckboxChange} />
            <label className='checkLabel'>{t('register.terms1a')}<a href='/terms' target='_blank'>{t('register.termsLink')}</a>、<a href='/privacy' target='_blank'>{t('register.privatcyLink')}</a>{t('register.terms1b')}</label>
          </div>
          <div className="termsCheckboxRow">
            <input type="checkbox" className='termsCheckbox'
              checked={isChecked2}
              onChange={handleCheckboxChange2} />
            <label className='checkLabel'>{t('register.terms2')}</label>
          </div>
        </div>
        <br />
      </form>

      {!smsVerity && (<button className="reg-submit-button-non-verity">{t('register.verity')}</button>)}

      {!isFormDataComplete && smsVerity && (
        <button className="reg-submit-button-non-verity">請填寫所有資料</button>)
      }

      {isFormDataComplete &&
        smsVerity &&
        isEmailValid(formData.email) &&
        isPasswordValid(formData.password) &&
        isPasswordMatch(formData.password, formData.confirmPassword) &&
        !isChecked &&
        (
          <button className="reg-submit-button-non-verity">請點選同意條款及細則</button>)
      }

      {!submitClicked && isFormDataComplete &&
        smsVerity &&
        isEmailValid(formData.email) &&
        isPasswordValid(formData.password) &&
        isPasswordMatch(formData.password, formData.confirmPassword) &&
        isChecked &&
        (
          <button onClick={handleSubmit} className="reg-submit-button">確認提交</button>)
      }

      {submitClicked && !regSuccess && (
        <ResisterSubmitDialog
          message={regMessage}
          regSuccess={regSuccess}
          handleBack={handleBack}
          handleCancel={handleCancel}
        />)}


      {showErrorDialog && (
        <SMSErrorDialog
          message={smsErrorMsg}
        />
      )}

    </div>
  );
};

export default RegisterComponent;


//    {isFormDataComplete && smsVerity && isEmailValid(formData.email) && isPasswordValid(formData.password) && isPasswordMatch(formData.password,formData.confirmPassword) &&(
//  <button className="reg-submit-button-non-verity">請填寫所有資料</button>)
//}