/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-09 17:52:16
 * @LastEditTime: 2024-05-30 21:46:32
 * @Description: 
 */
import React from 'react';
import '../css/ProductSelector.css';
import { useTranslation } from 'react-i18next';

function ProductSelector({ subProducts, category, setPointsNeeded, selectedProduct, setSelectedProduct, count, setCount }) {


  const { t } = useTranslation();


  const handleProductSelect = (product) => {
    setCount(0);
    setPointsNeeded(0)
    setSelectedProduct(product);
  };



  const increment = () => {
    setCount(prevCount => prevCount + 1);
    //console.log(subProducts);
    setPointsNeeded(subProducts[0].spec[0].price * (count + 1));
  };
  const decrement = () => {
    if (count > 1) {
      console.log(subProducts);
      setCount(prevCount => prevCount - 1);
      setPointsNeeded(subProducts[0].spec[0].price * (count - 1));
    }
  };

  return (
    <div>
      <span className='category_title'>{category}</span><br />
      <div className='product_select'>
        {subProducts && subProducts.map((product, index) => (
          <button
            key={index}
            className={selectedProduct === product ? 'product-selected' : 'product-unselected'}
            onClick={() => handleProductSelect(product)}>
            {product.product_name}
          </button>
        ))}
      </div>

      {selectedProduct && selectedProduct.spec && (
        <div className="spec-container">
          <div className='specs-wrapper'>
            {selectedProduct.spec.map((spec, index) => (
              <div key={index} className="spec">
                <div className="spec-upper">
                  <span className="spec-name">{t('product.qty')}({spec.specName})</span>
                </div>
                <div className="counter">
                  <button
                    className="quantity-btn"
                    onClick={decrement}>
                    －
                  </button>
                  <span className='qty_value'>{count}</span>
                  <button
                    className="quantity-btn"
                    onClick={increment}>
                    ＋
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

    </div>
  );
}

export default ProductSelector;
