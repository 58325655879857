/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-01-14 12:05:35
 * @LastEditTime: 2024-05-30 21:51:16
 * @Description: 
 */
import React from 'react';
import '../css/Banner.css'; 
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


const Banner = () => {

  return (
    <Carousel 
    showThumbs={false}
    showIndicators={false}
    showStatus	={false}
    autoPlay = {true}
    infiniteLoop = {true}
    emulateTouch = {true}
    interval={4500}

    
    >
    <div>
      <img src="/images/About_banner.jpg" alt="Image 1" />
    </div>
    <div>
      <img src="/images/About_banner0.jpg" alt="Image 2" />
    </div>
  </Carousel>
  );
};

export default Banner;
