import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../css/Nav.css'; // Make sure to create a corresponding CSS file


const Nav = () => {

  const { t } = useTranslation();

  return (
    <nav>

      <Link to="/">
        <div className="nav-logo">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      </Link>

      <div className="nav-content">
        <ul className="nav-links">
          <li><a href="/aboutUs">{t('nav.about')}</a></li>
          <li><a href="/pointsCal">{t('nav.points')}</a></li>
          <li><a href="/products">{t('nav.featured')}</a></li>
          <li><a href="/convertPoints">{t('nav.convert')}</a></li>
          <li><a href="/contactUs">{t('nav.enquire')}</a></li>
          {/*<li><a href="/products">List</a></li>*/}
        </ul>
      </div>

      <hr className="hr-solid"></hr>
    </nav>
  );
};

export default Nav;
