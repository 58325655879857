/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-25 17:03:06
 * @LastEditTime: 2024-05-26 21:12:04
 * @Description: 
 */
import React from 'react';
import '../css/ConfirmationDialog.css'; // Assuming you have corresponding CSS file

const AddToCartDialog = ({ postData, onCancel, onConfirm, conversionSuccess,handleBack }) => {



  return (

    <div className="confirmation-overlay">
      {!conversionSuccess && (
        <div className="confirmation-dialog">
          <h2>確認加到購物車</h2>
          <div className="product">
            <p><strong>{postData.products[0].fullProductName}</strong> (單個所需積分: {postData.products[0].price}) x <strong>{postData.products[0].qty}</strong></p>
          </div>
          <div className="button-container">
            <button onClick={onCancel}>返回</button>
            <button onClick={onConfirm}>確認</button>
          </div>
        </div>)}

      {conversionSuccess && (
        <div className="confirmation-dialog">
          <p>加入成功</p>
          <div className="button-container">
            <button onClick={handleBack}>返回</button>
          </div>
        </div>)}
    </div>
  );
};

export default AddToCartDialog;
