import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../css/PointsNew.css';

const PointsNew = () => {

  const { t } = useTranslation();

  return (
    
    <div className="points-section" id="points-div">
      <h2 className="points-title">{t('points.title')}</h2>
      <p className="points-description">{t('points.discription')}</p>  

<div className="container">
<div className="points-grid">
    <div className="component">
        <img src="/images/box1.png" alt="Description" />
        <div className="info-side">
        <img src="/images/box1_icon.png" alt="Icon 1" />
            <h2>{t('points.box1_title')}</h2>
            <p>{t('points.box1_discription')}</p>
              <div className="points-buttom">
              <Link to="https://api.whatsapp.com/send?phone=61032237" target='_blank'>
          <button className="gradient-button">{t('points.call_us')}</button>
          </Link>
          </div>
        </div>
    </div>
    <div className="component">
        <img src="/images/box2.png" alt="Description" />
        <div className="info-side">
        <img src="/images/box2_icon.png" alt="Icon 2" />
            <h2>{t('points.box2_title')}</h2>
            <p>{t('points.box2_discription')}</p>
              <div className="points-buttom">
              <Link to="https://api.whatsapp.com/send?phone=61032237" target='_blank'>
          <button className="gradient-button">{t('points.call_us')}</button>
          </Link>
          </div>
        </div>
    </div>
    <div className="component">
        <img src="/images/box3.png" alt="Description" />
        <div className="info-side">
        <img src="/images/box3_icon.png" alt="Icon 3" />
            <h2>{t('points.box3_title')}</h2>
            <p>{t('points.box3_discription1a')} <span>{t('points.box3_discription1b')}</span>{t('points.box3_discription1c')} <span>{t('points.box3_discription1d')}</span></p>
            <p>{t('points.box3_discription2a')} <span>{t('points.box3_discription2b')}</span>{t('points.box3_discription2c')} <span>{t('points.box3_discription2d')}</span></p>
              <div className="points-buttom">
          <Link to="/convertPoints">
          <button className="gradient-button">{t('points.convert_now')}</button>
          </Link>
          </div>
        </div>
    </div>
    <div className="component">
        <img src="/images/box4.png" alt="Description" />
        <div className="info-side">
        <img src="/images/box4_icon.png" alt="Icon 4" />
            <h2>{t('points.box4_title')}</h2>
            <p>{t('points.box4_discription')} <span>{t('points.box3_discription1b')}</span>{t('points.box3_discription1c')} <span>{t('points.box3_discription1d')}</span></p>
            <p>{t('points.box3_discription2a')} <span>{t('points.box3_discription2b')}</span>{t('points.box3_discription2c')} <span>{t('points.box3_discription2d')}</span></p>

              <div className="points-buttom">
              <Link to="/convertPoints">
          <button className="gradient-button">{t('points.convert_now')}</button>
          </Link>
          </div>
        </div>
    </div>
    <div className="component">
        <img src="/images/box5.png" alt="Description" />
        <div className="info-side">
        <img src="/images/box5_icon.png" alt="Icon 5" />
            <h2>{t('points.box5_title')}</h2> 
            <p>{t('points.box5_discription')} <span>{t('points.box3_discription1b')}</span>{t('points.box3_discription1c')} <span>{t('points.box3_discription1d')}</span></p>
            <p>{t('points.box3_discription2a')} <span>{t('points.box3_discription2b')}</span>{t('points.box3_discription2c')} <span>{t('points.box3_discription2d')}</span></p>
              <div className="points-buttom">
              <Link to="/convertPoints">
          <button className="gradient-button">{t('points.convert_now')}</button>
          </Link>
          </div>
        </div>
    </div>
    </div>
</div>
</div>

  );
};

export default PointsNew;
