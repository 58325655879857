/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-01-14 12:05:35
 * @LastEditTime: 2024-05-31 12:27:36
 * @Description: 
 */
import React ,{useEffect} from 'react';
import Header from '../components/Header';
import Nav from '../components/Nav';
import '../i18n/i18n'
import Footer from '../components/Footer';
import TermsComponent from '../components/TermsComponent';
import ReactGA from 'react-ga4';

const Terms = () => {

  useEffect(() => {
    document.title = "Good Club - 條款及細則"
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname ,title : 'Good Club - 條款及細則'});
  }, []);

  return (
    <div>
      <Header />
      <Nav />
      <TermsComponent />
      <Footer />
    </div>
  );
};

export default Terms;


