/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-09 17:52:16
 * @LastEditTime: 2024-05-30 21:47:04
 * @Description: 
 */
import React, { useEffect } from 'react';
import '../css/ProductSelector.css';
import { useTranslation } from 'react-i18next';

function ProductSelector({ subProducts, category, setPointsNeeded, selectedProduct, setSelectedProduct, quantities, setQuantities }) {


  const { t } = useTranslation();

  useEffect(() => {
    //console.log("useEffect triggered");
    //console.log(quantities);
    let newTotalPrice = 0;

    // Add selected product price
    if (selectedProduct) {
      newTotalPrice += selectedProduct.price || 0;
    }

    // Add spec prices based on quantities
    if (selectedProduct && selectedProduct.spec) {
      selectedProduct.spec.forEach(spec => {
        const quantity = quantities[spec.id] || 0;
        newTotalPrice += (spec.price || 0) * quantity;
      });
    }
    //console.log(quantities);
    //console.log("New Total Price:", newTotalPrice);

    setPointsNeeded(newTotalPrice);
  }, [quantities, selectedProduct,setPointsNeeded]);

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setQuantities({});
  };

  const handleQuantityChange = (specName, change) => {
    setQuantities(prevQuantities => ({
      ...prevQuantities,
      [specName]: Math.max((prevQuantities[specName] || 0) + change, 0),
    }));
  };

  return (
    <div>
      <span className='category_title'>{category}</span><br />
      <div className='product_select'>
        {subProducts && subProducts.map((product, index) => (
          <button
            key={index}
            className={selectedProduct === product ? 'product-selected' : 'product-unselected'}
            onClick={() => handleProductSelect(product)}>
            {product.product_name}
          </button>
        ))}
      </div>

      {selectedProduct && selectedProduct.spec && (
        <div className="spec-container">
          <div className='specs-wrapper'>
            {selectedProduct.spec.map((spec, index) => (
              <div key={index} className="spec">
                <div className="spec-upper">
                  <span className="spec-name">{t('product.qty')}({spec.specName})</span>
                </div>
                <div className="spec-lower">
                  <button
                    className="quantity-btn"
                    onClick={() => handleQuantityChange(spec.id, -1)}>
                    －
                  </button>
                  <span className='qty_value'>{quantities[spec.id] || 0}</span>
                  <button
                    className="quantity-btn"
                    onClick={() => handleQuantityChange(spec.id, 1)}>
                    ＋
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

    </div>
  );
}

export default ProductSelector;
