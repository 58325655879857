/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-09 17:52:16
 * @LastEditTime: 2024-06-11 15:47:07
 * @Description: 
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import '../css/Footer.css'; // Make sure to have a Footer.css file

const Footer = () => {

  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="footer-content"> {/* Content container */}
        <div className="footer-left">
          <img src="/images/bottom_logo.png" alt="Logo" className="footer-logo" />
          <div className="footer-icons">
            <a href="https://www.facebook.com/goodclubhk" target='_blank' rel="noreferrer"><img src="/images/facebook.png" alt="Icon 1" /></a>
            <a href="https://www.instagram.com/goodclubhk/" target='_blank' rel="noreferrer"><img src="/images/ig.png" alt="Icon 2" /></a>
            <a href="https://api.whatsapp.com/send?phone=85261032237" target='_blank' rel="noreferrer"><img src="/images/whatsappIcon.png" alt="Icon 3" /></a>
            <img src="/images/backtotop.png" alt="Icon 4" />
          </div>
        </div>
        <div className="footer-right">
          <a href="/aboutUs">{t('nav.about')}</a>
          <a href="/pointsCal">{t('nav.points')}</a>
          <a href="/products">{t('nav.featured')}</a>
          <a href="/convertPoints">{t('nav.convert')}</a>
        </div>

        <div className="footer-right">
          <a href="/contactUs">{t('nav.enquire')}</a>
          <a href="/terms">{t('nav.terms')}</a>
          <a href="/privacy">{t('nav.privacy')}</a>
          {/*<a href="/non_discrimination">{t('nav.nondis')}</a>*/}
        </div>

      </div>
      <span className='rights'> © GOOD CLUB 2024. ALL RIGHTS RESERVED.</span>
    </div>
  );
};

export default Footer;
