/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-25 17:03:06
 * @LastEditTime: 2024-05-26 21:05:34
 * @Description: 
 */
import React from 'react';
import '../css/ConfirmationDialog.css'; // Assuming you have corresponding CSS file

const AddToCartDialogType3 = ({ count, postData, onCancel, onConfirm, conversionSuccess, handleBack }) => {

  const productsDivs = postData.products.map(product => {
    // Add a "qty" key with a default value of 1 to each product
    return (
      <div key={product.productId} className="product">
        <p><strong>{product.fullProductName}</strong> (單個所需積分: {product.price}) x <strong>{count}</strong></p>
      </div>
    );
  });

  return (

    <div className="confirmation-overlay">
      {!conversionSuccess && (
        <div className="confirmation-dialog">
          <h2>確認加到購物車</h2>
          {productsDivs}
          <div className="button-container">
            <button onClick={onCancel}>返回</button>
            <button onClick={onConfirm}>確認</button>
          </div>
        </div>)}

      {conversionSuccess && (
        <div className="confirmation-dialog">
          <p>加入成功</p>
          <div className="button-container">
            <button onClick={handleBack}>返回</button>
          </div>
        </div>)}
    </div>
  );
};

export default AddToCartDialogType3;
