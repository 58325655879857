/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-01-14 12:05:35
 * @LastEditTime: 2024-05-31 16:50:59
 * @Description: 
 */
import React, { useEffect } from 'react';
import Header from '../components/Header';
import Nav from '../components/Nav';
import BannerAboutUs0 from '../components/Banner_aboutUs0';
import BannerAboutUs2 from '../components/Banner_aboutUs2';
import '../i18n/i18n'
import Footer from '../components/Footer';
import About from '../components/About';
import About2 from '../components/About2';
import ReactGA from 'react-ga4';



const AboutUs = () => {

  useEffect(() => {
    document.title = "Good Club - 關於我們"
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname ,title : 'Good Club - 關於我們'});
  }, []);

  return (
    <div>
      <Header />
      <Nav />
      <BannerAboutUs0 />
      <About />
      <BannerAboutUs2 />
      <About2 />
      <Footer />
    </div>
  );
};

export default AboutUs;


