/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-23 21:40:18
 * @LastEditTime: 2024-06-11 15:46:04
 * @Description: 
 */
import React from 'react';
import '../css/WhatsAppIcon.css';

const WhatsAppIcon = () => {
  const handleClick = () => {
    // Replace the URL with your WhatsApp chat link
    window.open('https://api.whatsapp.com/send?phone=85261032237', '_blank');
  };

  return (
    <div className="whatsapp-icon" onClick={handleClick}>
      {/* You can use an image or an icon library like FontAwesome */}
      <img src="/images/whatsppIcon.png" alt="WhatsApp Icon" />
    </div>
  );
};

export default WhatsAppIcon;
