/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-25 17:03:06
 * @LastEditTime: 2024-05-28 21:45:16
 * @Description: 
 */
import React from 'react';
import '../css/ConfirmationDialog.css'; // Assuming you have corresponding CSS file


const handleBack = () => {
  window.location.reload(); // This will refresh the page
};

const SMSErrorDialog = ({ message }) => {


  return (

    <div className="confirmation-overlay">

      <div className="confirmation-dialog">
        <p>{ message } </p>
        <div className="button-container">
          <button onClick={handleBack}>返回</button></div>
      </div>

    </div>
  );
};

export default SMSErrorDialog;
