/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-01-14 12:05:35
 * @LastEditTime: 2024-05-31 12:27:10
 * @Description: 
 */
import React ,{useEffect} from 'react';
import Header from '../components/Header';
import Nav from '../components/Nav';
import '../i18n/i18n'
import Footer from '../components/Footer';
import ConvertPointsComponent from '../components/ConvertPointsComponent';
import ReactGA from 'react-ga4';
ReactGA.initialize('G-60DJ883TPB');

const ConvertPoints = () => {

  useEffect(() => {
    document.title = "Good Club - 會員兌換"
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname ,title : 'Good Club - 會員兌換'});
  }, []);

  return (
    <div>
      <Header />
      <Nav />
      <ConvertPointsComponent />
      <Footer />
    </div>
  );
};

export default ConvertPoints;


