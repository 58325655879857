/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-04 17:03:13
 * @LastEditTime: 2024-05-28 17:39:16
 * @Description: 
 */

import React, { useState, useEffect } from 'react';
import '../css/Products.css'; // Ensure you have a corresponding CSS file
import productIcon from '../assets/point_icon.png'; // Update with the path to your product icon
import Pagination from './Pafination';
import { useTranslation } from 'react-i18next';
import '../css/Featured.css'
import '../css/Pagination.css'
import { API_PREFIX } from './ApiConfog';

const FeaturedFull = () => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 15;
  const { t } = useTranslation();
  const orderApiUrl = `${API_PREFIX}auth/product`;

  useEffect(() => {
    // Replace with your actual API call
   // fetch('https://dummyjson.com/products')
   fetch(orderApiUrl)
      .then(response => response.json())
      .then(data => {
        if (data && data.products && Array.isArray(data.products)) {
          setProducts(data.products);
        } else {
          console.error('Data is not in expected format:', data);
        }
      })
      .catch(error => console.error('Error fetching products:', error));
  }, []);

  // Calculate pagination
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (


    <div className="products-container">
      <h2>{t('nav.featured')}</h2>
      <div className="product-grid">
        {currentProducts.map(product => (
          <div key={product.id} className="product-card">
            <a href={"/products/" + product.id}><img src={product.thumbnail} alt={product.name} className="product-image" /></a>
            <div className="product-info">
              <div className="product-name">{product.title}</div>
              <div className="product-add" ><a href={"/products/" + product.id}><img src="/images/cross_buttom.png" alt="Icon"/></a></div> 
            </div>
            <div className="product-details">
            <div className="product-price-front">
                <img src="/images/greyIcon.png" alt="Icon" className="product-icon" />
                {product.price}
              </div>
            </div>
          </div>
        ))}
      </div>
      <Pagination
        productsPerPage={productsPerPage}
        totalProducts={products.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  );
};

export default FeaturedFull;
