/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-09 17:52:16
 * @LastEditTime: 2024-06-11 15:47:54
 * @Description: 
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import '../css/Contact.css';
import useScrollToAnchor from '../until/useScrollToAnchor';

const Contact = () => {

  const { t } = useTranslation();
  useScrollToAnchor();

  return (
    <div className="contact-div">
      <h3>{t('contact.big_title')}</h3>
      <div className="split-component">
        <div className="left-column">
          <img src="/images/contactUs.png" alt="contact us" />
        </div>
        <div className="right-column">
          <div className='right-title'>
            <h2>{t('contact.title')}</h2>
          </div>
          <div className="contact_content">
            <p>{t('contact.address')}</p>
            <a href="https://maps.app.goo.gl/kMeEqw2n1qCkbavs9" target='_blank'><p>{t('contact.full_address')}</p></a>
            
          </div>

          <div className="contact_content">
            <p>{t('contact.phone')}</p>
            <a href="tel:61032237"><p>61032237</p></a>
          </div>

          <div className="contact_content">
            <p>WhatsApp :</p>
            <a href="https://api.whatsapp.com/send?phone=85261032237" target='_blank'><p>61032237</p></a>
          </div>

          <div className="contact_content">
            <p>{t('contact.email')}</p>
            <a href="mailto:info@goodclub.hk"><p>info@goodclub.hk</p></a>
          </div>

          <div className="contact_content">
            <p>{t('contact.instagram')}</p>
            <a href="https://www.instagram.com/hkgdmask/" target='_blank'><p>hkgdmask</p></a>
          </div>

          <div className="contact_content">
            <p>{t('contact.facebook')}</p>
            <a href="https://www.facebook.com/hkgoodmask/" target='_blank'><p>hkgoodmask</p></a>
          </div>


        </div>


      </div>
    </div>
  );
};

export default Contact;
