/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-25 17:03:06
 * @LastEditTime: 2024-06-02 21:36:57
 * @Description: 
 */
import React, { useState } from 'react';
import '../css/ChangeDataDialog.css'; // Assuming you have corresponding CSS file
import { API_PREFIX } from './ApiConfog';


const ChangeDataDialog = ({ setShowChangeData, userId }) => {


  const [errMsg, setErrMsg] = useState('');
  const [changeSuccess, setChangeSuccess] = useState(false);
  const changeDataApiUrl = `${API_PREFIX}user/changeData`;
  const token = localStorage.getItem("accessToken");


  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [formData, setFormData] = useState({
    userId: userId,
    oldPassword: '',
    newPassword: '',
  });



  const isPasswordValid = (oldPassword) => {
    if (oldPassword.length < 8) {
      return false; // Password is too short
    }
    return true; // Password is valid
  };

  const isPasswordMatch = (newPassword, confirmNewPassword) => {
    if (newPassword.length < 8) {
      return false; // Password is too short
    }
    if (newPassword !== confirmNewPassword) {
      return false; // Passwords don't match
    }
    return true; // Password is valid
  };

  const handleSubmit = () => {
    const postData = async () => {
      try {
        const response = await fetch(changeDataApiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}` // Include the token in the Authorization header
          },
          body: JSON.stringify({ userId: formData.userId, oldPassword: formData.oldPassword, newPassword: formData.newPassword })
        });
        if (response.ok) {
          const responseData = await response.json();
          if (responseData.success) {
            // Store access token in local storage or session storage
            setChangeSuccess(true);
            console.log("ChangeOK")
          } else {
            // Display error message if registration is unsuccessful
            setErrMsg('密碼不正確');
          }
        } else {
          // Handle HTTP error responses (status not 2xx)
          console.error('HTTP error:');
        }
      } catch (error) {
        console.error('Error submitting data:', error);
      }
    };
    postData();

  };



  return (

    <div className="confirmation-overlay">

      {!changeSuccess && (<div className="confirmation-dialog">
        <h2 style={{ textAlign: 'center' }}>更改密碼</h2>
        <span className='errMsg'>{errMsg}</span>
        <p>請輸入原有密碼 : </p>
        <input
          type="password"
          id="oldPassword"
          name="oldPassword"
          placeholder="請輸入原有密碼"
          onChange={handleInputChange}
          value={formData.oldPassword}
          required
        />
        <p>請輸入新密碼 :  {formData.newPassword && !isPasswordValid(formData.newPassword) && (<span style={{ color: 'red', fontWeight: 'bold' }}>密碼長度不足(最少8位)</span>)} </p>

        <input
          type="password"
          id="newPassword"
          name="newPassword"
          placeholder="請輸入新密碼"
          onChange={handleInputChange}
          value={formData.newPassword}
          required
        />

        <p>請再次輸入新密碼 : {formData.newPassword && !isPasswordMatch(formData.newPassword, formData.confirmNewPassword) && (<span style={{ color: 'red', fontWeight: 'bold' }}>密碼不匹配</span>)}</p>

        <input
          type="password"
          id="confirmNewPassword"
          name="confirmNewPassword"
          placeholder="請再次輸入新密碼"
          onChange={handleInputChange}
          value={formData.confirmNewPassword}
          required
        />
        <br />

        <button
          disabled={
            !(
              isPasswordValid(formData.newPassword) &&
              isPasswordMatch(formData.newPassword, formData.confirmNewPassword) &&
              formData.oldPassword
            )
          }
          onClick={() => {
            if (
              isPasswordValid(formData.newPassword) &&
              isPasswordMatch(formData.newPassword, formData.confirmNewPassword) &&
              formData.oldPassword
            ) {
              handleSubmit();
            } else {
              console.error('One or more conditions are not met');
            }
          }}
        >
          確認
        </button>


        <button onClick={() => setShowChangeData(false)} >返回</button>
        <div className="button-container">
        </div>

      </div>)}



      {changeSuccess && (<div className="confirmation-dialog">
        <h2 style={{ textAlign: 'center' }}>更改成功</h2>
        
        <button onClick={() => setShowChangeData(false)} >返回</button>
        <div className="button-container">
        </div>

      </div>)}




    </div>
  );
};

export default ChangeDataDialog;
