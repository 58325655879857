/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-23 21:40:18
 * @LastEditTime: 2024-05-31 12:18:45
 * @Description: 
 */
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home'; // Your home component
import Register from './pages/Register';
import ProductList from './pages/ProductList';
import AboutUs from './pages/AboutUs';
import PointsCal from './pages/PointsCal';
import ContactUs from './pages/ContactUs';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import NonDis from './pages/NonDis';
import Login from './pages/Login';
import ConvertPoints from './pages/ConvertPoints';
import Products from './pages/Products';
import Cart from './pages/Cart';
import { AuthProvider } from './context/AuthContext';
import WhatsAppIcon from './components/WhatsApp';
import './minWidth.css';
import ReactGA from 'react-ga4';



const App = () => {

  ReactGA.initialize('G-60DJ883TPB');

  return (
    <AuthProvider>
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/pointsCal" element={<PointsCal />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/register" element={<Register />} />
        <Route path="/products" element={<ProductList />} />
        <Route path="/products/:id" element={<Products />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/convertPoints" element={<ConvertPoints />} />
        <Route path="/non_discrimination" element={<NonDis />} />
      </Routes>
    </Router>
    <WhatsAppIcon />
    </AuthProvider>
  );
};

export default App;
