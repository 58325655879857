import React from 'react';
import { useTranslation } from 'react-i18next';
import useScrollToAnchor from '../until/useScrollToAnchor';
import '../css/TermsComponent.css'; 

const Non_disComponent = () => {

  const { t } = useTranslation();
  useScrollToAnchor();

  return (
    <div className="terms-section" id="terms-div">
      <h2>{t('non-dis.title')}</h2>
      <p>{t('non-dis.content1')}</p>
    </div>
  );
};

export default Non_disComponent;
