/*
 * @Author: Michael Lam 127068684+MichaelLam888@users.noreply.github.com
 * @Date: 2024-05-09 17:52:16
 * @LastEditTime: 2024-06-12 13:57:11
 * @Description: 
 */
import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../css/Header.css'
import { AuthContext } from '../context/AuthContext';
import { API_PREFIX } from './ApiConfog';

const Header = () => {
  const { t, i18n } = useTranslation();
  const { userInfo, logout } = useContext(AuthContext);
  const [headerPoints, setHeaderPoints] = useState(0);
  const [cartItems, setCartItems] = useState(0);
  const token = localStorage.getItem("accessToken");
  const headerInfoApiUrl = `${API_PREFIX}user/headerInfo`;

  const [currentLanguage, setCurrentLanguage] = useState('zh'); // Default language
  const changeLanguage = (language) => {
    //console.log("changed to " + language);
    i18n.changeLanguage(language);
    setCurrentLanguage(language);
  };

  const handleSearch = () => {
    //console.log("Search initiated");
    // Implement search logic
  };

  const handleLogin = () => {
  };

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    //console.log("Header Use effect");
    if (userInfo) {
      const postData = { id: userInfo.userId };
      // Make a POST request to the API with the token and data
      fetch(headerInfoApiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        },
        body: JSON.stringify(postData), // Include the data object here
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data");
          }
          return response.json();
        })
        .then((responseData) => {
          // Handle the response data
          setCartItems(responseData.cartItems);
          setHeaderPoints(responseData.goodclubPoints);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [userInfo]); // Include userInfo in the dependency array

  return (
    <header>
      <div className="header-left">
        {/*
                <div className="language-switch">
          <span className={currentLanguage === 'en' ? 'active-language' : ''}
            onClick={() => changeLanguage('en')}>EN</span>
          <span> | </span>
          <span className={currentLanguage === 'zh' ? 'active-language' : ''}
            onClick={() => changeLanguage('zh')}>CH</span>
        </div>
        */}


        {
          /* Display user info if logged in */
          /*<button onClick={handleLogout}>{t('header.logout')}</button>*/
        }
        {userInfo && (
          <div className="header-user-info">

            <span><img src="/images/greyIcon.png" alt="Icon" /></span><span>{headerPoints}</span>
            <div className="header-user-welcome">
              <span>&nbsp;&nbsp;WELCOME,{userInfo.userName}(ID:{userInfo.userId})</span>
            </div>
          </div>
        )}

      </div>
      <div className="header-right">
        <Link to="/">
          <img className="home-button" src="/images/home.png" onClick={handleSearch} alt="Home" />
        </Link>

        {!userInfo && (
          <Link to="/login">
            <img className="search-button" src="/images/cart_icon.png" onClick={handleSearch} alt="Search" />
          </Link>
        )}

        {userInfo && cartItems === 0 && (
          <Link to="/cart">
            <img className="search-button" src="/images/cart_icon.png" onClick={handleSearch} alt="Search" />
          </Link>
        )}


        {userInfo && cartItems > 0 && (
          <Link to="/cart">
            <div className="cart-icon-container">
              <span className="red-circle">
                <span className="white-number">{cartItems}</span>
              </span>
              <img className="search-button" src="/images/cart_icon.png" alt="Icon" onClick={handleSearch} />
            </div>
          </Link>
        )}


        {!userInfo && (
          <Link to="/login">
            <button onClick={handleLogin} className="login-button">{t('header.login')}</button>
          </Link>
        )}
        {userInfo && (

          <button onClick={handleLogout} className="login-button">登出</button>

        )}


      </div>
    </header>
  );
};

export default Header;
